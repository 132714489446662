<template>
    <div>
        <a-modal title="编辑客服" :width="540" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <div>
                <a-spin :spinning="loading">
                    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 6}" :wrapper-col="{ span: 18}">
                        <a-form-model-item label="客服二维码" required>
                            <upload-img v-model="form.or_code"></upload-img>
                        </a-form-model-item>

                        <a-form-model-item label="客服名称" required>
                            <a-input v-model="form.name" style="width: 300px;" />
                        </a-form-model-item>

                        <a-form-model-item label="客服手机号" required>
                            <a-input v-model="form.mobile" style="width: 300px;" />
                        </a-form-model-item>
                    </a-form-model>
                </a-spin>
            </div>
        </a-modal>
    </div>
</template>

<script>
import uploadImg from '../../../../../components/upload/upload.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        customer_service_id: {
            type: Number,
            default: 0,
        },
        customer_service_info: {
            type: Object,
            default: {},
        }
    },
    components: {
        uploadImg,
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            form: {
                name: '',
                or_code: '',
                mobile: '',
            },
        }
    },
    created() {
        if (this.customer_service_id != 0) {
            this.loaddata();
        }

    },
    methods: {

        loaddata() {
            this.form = {
                name: this.customer_service_info.name,
                or_code:  this.customer_service_info.or_code,
                mobile:  this.customer_service_info.mobile,
            }
        },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * 提交数据
         */
        onSubmit() {
            if (this.confirmLoading == true) return;
            this.confirmLoading = true;
            let url = 'api/store/CustomerAdd'
            let datas = this.form
            if (this.customer_service_id != 0) {
                url = 'api/store/CustomerEdit'
                datas.id = this.customer_service_id
            }
            this.$http.api(url, datas).then(res => {
                this.$message.success('保存成功', 1, () => {
                    this.confirmLoading = false;
                    this.$emit("ok");
                })
            }).catch(res => {
                this.confirmLoading = false;
                console.log(res);
            })

        },

    }
}
</script>

<style>
</style>
